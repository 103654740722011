import React, {Component} from 'react';
import {InputItem} from 'antd-mobile';

const cls = 'trust-mobile-input';

class MobileInputItem extends Component {
    render() {
        const {clear=false,type = 'text', labelNumber = 5, error = false, children = '', onErrorClick = '', tipStyle = {}, ...other} = this.props;
        const errorTipStyle = {
            color: '#f5222d',
            padding: '5px 0px 5px 10px',
            textAlign: 'left',
            position: 'relative',
            fontSize: 12,
            ...tipStyle
        }

        return (
            <div className={cls}>
                <InputItem type={type} {...other} error={false} clear={clear}>{children}</InputItem>
                {
                    error &&
                    <div className="am-list-item" style={{height: 'auto', minHeight: 0}}>
                        {
                            children && <div className={`am-input-label am-input-label-${labelNumber}`}></div>
                        }
                        <div className="am-input-control">
                            <div style={{...errorTipStyle}}>
                                {onErrorClick}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default MobileInputItem;
import React, { useState } from 'react';
import {Button,Toast} from 'antd-mobile'
import './css/home.css'
import {getQRCode} from '../../api/requstApi'
var QRCode = require('qrcode.react');
function PromoterContent() {
    const [showCode, setShowCode] = useState(false);
    const [code, setCode] = useState(false);
    async function getCode() {
        Toast.loading("加载中",0)

        let res = await getQRCode()
        if(res.code==200){
            setCode(res.result)
            setShowCode(true)
            Toast.hide()
        }else{
            Toast.fail("网络错误")
        }

    }
    function goSearchDriver() {
        window.location.href="./#/promoterDriverList"
    }
    return (
        <div className={"PromoterHome"}>
            <div style={{color:"#f29c1f",fontSize:"22px"}}>安巡代驾</div>
            <div style={{display:"flex",justifyContent:"center",marginTop:"30px"}}>
                <button className={"btnlogin"} onClick={getCode}>获取推广人二维码</button>
            </div>
            <div style={{display:"flex",justifyContent:"center",marginTop:"30px"}}>
                <button className={"btnlogin"} onClick={goSearchDriver}>查询推广下的司机</button>
            </div>
            {
                showCode?
                <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",
                    background:""}}>
                    <QRCode value={code} size={280}/>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <button className={"btnlogin"} style={{marginTop:"20px"}} onClick={()=>{
                            setShowCode(false)
                        }}>关闭</button>
                    </div>
                </div>:""
            }
        </div>
    );
}
export default PromoterContent
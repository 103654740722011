import React, {Component} from 'react';
import './installation.less'
import titleImg from '../../../assets/images/titleCode.png'
import androidCode from '../../../assets/images/android.png'
import backImg from '../../../assets/images/layer.png'

let titleCode = {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${titleImg})`,
};

export default class installation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browserType: ""
        }
    }

    componentDidMount() {
        this.getBrowser();
        document.getElementsByTagName("title")[0].innerText = "单聚多代驾";
    }

    getBrowser = () => {
        let userAgent = navigator.userAgent;
        if (userAgent.indexOf("MicroMessenger") > -1) {
            this.setState({
                browserType: "WeiXin",
            })
        } else {
            this.setState({
                browserType: ""
            })
        }
    }

    install = () => {
        let phoneType = navigator.userAgent;
        let isAndroid = phoneType.indexOf('Android') > -1 || phoneType.indexOf('Adr') > -1;//android终端
        let isiOS = !!phoneType.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            window.location.href = "https://ali-oss.safephone.cn/apk/driver/danjuduo_daijia.apk";
        }
        if (isiOS) {
            window.open("itms-apps://itunes.apple.com/app/id1585753819");
        }
    }

    render() {
        const {browserType} = this.state;

        return (
            <div className={browserType == "WeiXin" ? "installation noSliding": "installation"}>
                <div className={browserType == "WeiXin" ? "back" : "noBack"}><img src={backImg} className={"backImg"}
                /></div>
                <div className="titleBar" style={titleCode}></div>
                <div className="wrapper">
                    <div className="wrapperList">
                        <p>迭代：代驾479主版</p>
                        <p>环境：正式环境</p>
                        <p>生成时间：2021-08-16 19:08:00</p>
                    </div>
                    <div className="wrapperList">
                        <p>您可以点击按钮或者扫描二维码安装</p>
                        <p>IOS版本下载完成后，打开【设置-通用-描述文件与设备管理】选择【Autonavi Software Co.，Ltd】并信任证书</p>
                    </div>
                    <div className="productList">
                        <div className="item">
                            <div className="item-tit">
                                <p className={"item-tit-text"}>单聚多代驾司机端</p>
                            </div>
                            <div className="item-desc">
                                <p className="version">版本：4.80.0.0030</p>
                                <p className="size">大小：86.1MB</p>
                            </div>
                            <div className="item-code">
                                <div className="alert-text">禁止外泄</div>
                                <div className="tag">release</div>
                                <img src={androidCode}
                                     alt={"https://ali-oss.safephone.cn/apk/driver/danjuduo_daijia.apk"}
                                     className={"img"}/>
                            </div>
                            <p className="code-import">请使用浏览器或钉钉扫码下载</p>
                            <div className="item-btn" onClick={this.install}>
                                点击安装
                            </div>
                        </div>

                    </div>
                </div>
                <div className="footer">忠诚的伙伴，忠诚的运力</div>
            </div>
        )
    }
}
/**
 *
 */
import React, {Component} from 'react'
import './admin.less'
import {Redirect, Route, Switch} from 'react-router-dom'
import sessionUtil from '../../utils/sessionUtils'
import menuList from '../../config/menusConf/menu'
/**
 * 路由页面
 */
import NotFound from '../../pages/error_pages/not-found'

export default class Admin extends Component {
    state={
        divHeight:0
    }
    div = React.createRef();
    componentDidMount(){
        this.getdiv()
    }
    render() {
            const user = sessionUtil.getUser();
            if(!user||!user.token){
                return <Redirect to="/driverHome"/>
            }
        return (
            <Switch>
                <Redirect exact={true} from='/' to={this.setMeuListWithPermission(menuList).urlList[0]||'/'}/>

                <Route component={NotFound}/>
            </Switch>
        )
    }

    /**
     * 获取父元素高度
     */
    getdiv=()=>{
        if(this.div.current){
            let height = this.div.current.offsetHeight
            this.setState({divHeight:height})
        }
    }


    setMeuListWithPermission=(menuListObj)=>{
        let menuList = JSON.parse(JSON.stringify(menuListObj));
        let data = {}
        let urlList = [];
        let user = sessionUtil.getUser();
        let permissionList = user.permissionList;
        for(let i=0;i<menuList.length;i++){
            let item = menuList[i];
            if(!permissionList.includes(item.permission)){
                menuList.splice(i,1);
                i--;
            }
            else {
                // urlList .push(item.key);
                item.subs = this.setMeuSubListWithPermission(item.subs,urlList)
            }
        }
        data.menuList = menuList
        data.urlList = urlList
        return data
    }

    setMeuSubListWithPermission=(menuList,urlList)=>{
        let user = sessionUtil.getUser();
        let permissionList = user.permissionList;
        for(let i=0;i<menuList.length;i++){
            let item = menuList[i];
            if(!permissionList.includes(item.permission)){
                menuList.splice(i,1);
                i--;
            }else {
                urlList.push(item.key)
            }
        }
        return menuList
    }


}
import React from 'react';
import {Button, InputItem, List, Radio, Toast ,Icon} from 'antd-mobile';
import {createForm} from 'rc-form';
import "./login.less"
import { reqSendVsCode, reqDriverLogin} from "../../../api/index";
import {SUCCESS} from "../../../utils/constants";
import MobileInputItem from "../../../common/mobileInputItem/MobileInputItem";
import sessionUtil from '../../../utils/sessionUtils'
import Background from '../../../assets/images/background.png'

var sectionStyle = {
    width: "100%",
    height: "100%",
// makesure here is String确保这里是一个字符串，以下是es6写法
    backgroundImage: `url(${Background})`,
    backgroundSize:'100% 100%',
    top:0,
    padding:0,
    bottom:0,
    position:"fixed"
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            loading:false,
            imageBase64Str:"",
            imageTicket:"",
            clickCount:0,
        }
    }
    componentDidMount() {
        // this.getVsCodeImage();
    }

    render() {
        const _that = this;
        const ownStyle={
            width: 'calc(100% - 10px)',
            height: '45px',
            backgroundColor: '#ffffff'
        }
        const {imageBase64Str, clickCount} = this.state
        const { getFieldProps,getFieldDecorator, getFieldError} = this.props.form;
        const {type = 'text', labelNumber = 5, error = false, children = '', onErrorClick = '', tipStyle = {}, ...other} = this.props;
        const errorTipStyle = {
            color: '#f5222d',
            padding: '5px 0px',
            textAlign: 'left',
            position: 'relative',
            fontSize: 12,
            ...tipStyle
        }
        return (
            <div className={"login-page"} style={sectionStyle}>
                <div className={"login-header"}>
                    <h3>
                        在线报名
                    </h3>
                    <p>
                        安巡代驾司机招募
                    </p>
                </div>
                <List>
                    <div className={"input-content"}>
                        <div className={"input-box"}>
                            <MobileInputItem
                                {...getFieldProps('phoneNumber', {rules:[
                                    {required: true, message: "请输入您的手机号"}
                                ]})}
                                clear
                                className={"input-box-input-item"}
                                placeholder="请输入手机号码"
                                style={{width:"300px",background:"transparent;"}}
                                error={!!getFieldError('phoneNumber')}
                                onErrorClick={getFieldError('phoneNumber')}
                            />
                        </div>
                        {/*<div className={"input-box"}>*/}
                        {/*    <MobileInputItem*/}
                        {/*        {...getFieldProps('vcode', {rules:[*/}
                        {/*            {required: true, message: "请输入图中验证码"}*/}
                        {/*        ]})}*/}
                        {/*        placeholder="请输入图中验证码"*/}
                        {/*        className={"input-box-input-item"}*/}
                        {/*        style={{width:"200px"}}*/}
                        {/*        error={!!getFieldError('vcode')}*/}
                        {/*        onErrorClick={getFieldError('vcode')}*/}
                        {/*    />*/}
                        {/*    <div className={"input-box-item"}>*/}
                        {/*        <div onClick={()=>{this.getVsCodeImage()}}>*/}
                        {/*            <img src={imageBase64Str} style={{width:'70%'}}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={"input-box"}>
                            <MobileInputItem
                                {...getFieldProps('verifyCode', {rules:[
                                    {required: true, message: "请输入短信验证码"}
                                ]})}
                                clear
                                placeholder="请输入短信验证码"
                                className={"input-box-input-item"}
                                extra="获取验证码"
                                style={{width:"230px"}}
                                onExtraClick={()=>{this.getVsCode()}}
                                error={!!getFieldError('verifyCode')}
                                onErrorClick={getFieldError('verifyCode')}
                            />
                            {/*<div className={"input-box-item"}>*/}
                                {/*<Button onClick={()=>{this.getVsCode()}}>获取验证码</Button>*/}
                            {/*</div>*/}
                        </div>
                        <div className={"input-footer"}>
                            {/*<Radio*/}
                            {/*    className={"input-radio"}*/}
                            {/*    name={"agree"}*/}
                            {/*>我已阅读并同意</Radio>*/}
                            <input type={"radio"} name={"agree"} className={"input-radio"} value={1} onClick={(e)=>{
                                e.target.checked = clickCount%2===0?true: false
                                this.setState({
                                    clickCount: clickCount+1
                                })
                            }}/>我已阅读并同意
                            <a style={{color:"#176af0"}} href={"https://h5.yueyuechuxing.cn/yueyue/comtext/index.html?code=driverUserAgreement&tenantId=800230&isUnicorn=true"}>《用户协议》</a>
                        </div>
                    </div>
                </List>
                <Button type={"primary"} onClick={this.login} style={{width:"80%",margin:"auto",marginTop:"20px"}} >
                    登陆
                </Button>
            </div>
        );
    }

    // getVsCodeImage=async ()=>{
    //     const res = await reqGetImageVerifyCode();
    //     if(res.code === SUCCESS){
    //         this.setState({
    //             imageBase64Str: res.result.imageBase64Str,
    //             imageTicket: res.result.imageTicket,
    //         })
    //     }
    // }

    getVsCode=async ()=>{
        const form = this.props.form;
        const _that = this;
        form.validateFields(["phoneNumber", "vcode"]).then(async (values)=>{
            const {imageTicket} = _that.state;
            values.imageTicket = imageTicket;
            let param = {
                imageTicket: imageTicket,
                mobile: values.phoneNumber,
                imageCode: values.vcode,
            };
            const res = await reqSendVsCode(param);
            if(res.code === SUCCESS){
                Toast.success("发送验证码成功", 1);
            }else{
                Toast.fail(res.message, 1)
            }
        })
    }

    loading =(value)=>{
        this.setState({loading:value})
    }

    getQueryVariable=(variable)=> {
        let href = window.location.href;
        if(href.indexOf("?")>0){
            let query = href.substring(href.indexOf("?")+1);
            let vars = query.split("&");
            for (let i=0;i<vars.length;i++) {
                let pair = vars[i].split("=");
                if(pair[0] === variable){return pair[1];}
            }
        }
    }

    login=()=>{
        const form = this.props.form;
        const _that = this;
        form.validateFields(["phoneNumber", "verifyCode"]).then(async (values)=>{
            const {clickCount} = _that.state;
            if(clickCount%2===0){
                Toast.fail("请阅读并同意《用户协议》")
                return false;
            }else{
                const res = await reqDriverLogin(values.phoneNumber, values.verifyCode);
                if(res.code === SUCCESS){
                    Toast.success("登录成功", 1);
                    sessionUtil.saveUser(res.result.accessToken, res.result.refreshToken, this.getQueryVariable("promoterId"), values.phoneNumber);
                    this.props.history.replace(`/registry`);
                }else{
                    Toast.fail(res.message, 1)
                }
            }
        })
    }
}

const LoginWrapper = createForm()(Login);
export default LoginWrapper;
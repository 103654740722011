import React, {useEffect, useState} from 'react';
import {Button,Toast,Pagination, Icon} from 'antd-mobile'
import './css/home.css'
import {getDriverList} from '../../api/requstApi'
function PromoterDriverList() {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(0);
    useEffect( ()=>{
        getList(1)
    },[])
    async function getList(page) {
        Toast.loading("加载中",0)
        let res=await getDriverList(page,8)
        if(res.code==200){
            setCurrent(page)
            setList(res.results)
            setTotal(res.totalCount)
        }else{
            Toast.fail("网络错误")
        }
        Toast.hide()
    }

    return (
        <div className={"PromoterHome2"}>
            <div style={{color:"#f29c1f",fontSize:"22px",width:"100%",textAlign:"center",padding:"15px 0"}}>司机名单</div>
            <div style={{display:"flex",color:"white",fontSize:"20px",borderBottom:"1px solid white",padding:"10px 0"}}>
                <div style={{width:"30%",textAlign:"center"}}>注册时间</div>
                <div style={{width:"20%",textAlign:"center"}}>姓名</div>
                <div style={{width:"20%",textAlign:"center"}}>性别</div>
                <div style={{width:"30%",textAlign:"center"}}>电话</div>
            </div>
            {list.map((item,index)=>{
                return <div style={{display:"flex",color:"white",fontSize:"16px",borderBottom:"1px solid white",padding:"10px 0"}}>
                            <div style={{width:"30%",textAlign:"center"}}>{item.createTime}</div>
                            <div style={{width:"20%",textAlign:"center"}}>{item.driverName}</div>
                            <div style={{width:"20%",textAlign:"center"}}>{item.sex==1?"男":"女"}</div>
                            <div style={{width:"30%",textAlign:"center"}}>{item.phoneNumber}</div>
                        </div>
            })}
            <Pagination total={Math.ceil(total / 8)}
                        className="custom-pagination-with-icon"
                        current={current}
                        style={{marginTop:"20px",color:"white"}}
                        onChange={ (e)=>{
                            console.log(e)
                            getList(e)
                        }}
                        locale={{
                            prevText: (<span className="arrow-align">上一页</span>),
                            nextText: (<span className="arrow-align">下一页</span>),
                        }}
            />
        </div>
    );
}
export default PromoterDriverList
import React, {Component} from 'react';
/**
 * BrowserRouter :路由器
 * HashRouter 带#号
 *
 * Route:路由
 */
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom'
/**
 * 路由页面
 */
import LoginWrapper from './components/driver/login/login'
import Admin from './pages/admin/admin'
import RegistryWrapper from './components/driver/registry/registry'
import Home from './components/driver/home/home'

import PromoterHome from './components/promoter/home'
import PromoterContent from './components/promoter/content'
import PromoterDriverList from './components/promoter/list'

import DriverHome from './components/driver/home/home'
import installation from './components/driver/installation/installation'

import "./pages/main.less"

var width = document.documentElement.clientWidth
document.documentElement.style.fontSize=(width/10)+"px"
export default class App extends Component{

    render(){
        return (
            <HashRouter>
                <Switch>
                    <Route path='/login' component={LoginWrapper}/>
                    <Route path='/promoterHome' component={PromoterHome}/>
                    <Route path='/promoterContent' component={PromoterContent}/>
                    <Route path='/promoterDriverList' component={PromoterDriverList}/>
                    <Route path='/driverHome' component={DriverHome}/>
                    <Route path='/installation' component={installation}/>

                    <Route path='/registry' component={RegistryWrapper}/>

                    <Route path='/' component={Admin}/>
                    <Redirect to="/promoterHome"/>
                </Switch>
            </HashRouter>
        )
    }
}

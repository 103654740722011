
const USER_KEY = 'user'
const COM_KEY ='compound'
const MODULE_KEY='module'
const USERINFO_KEY = 'userInfo'
export  default  {
    //保存user
    saveUser(accessToken, refreshToken, promoterId, phoneNumber){
        //localStorage.setItem(USER_KEY,JSON.stringify(user));
        sessionStorage.setItem(USER_KEY, JSON.stringify({
            accessToken: accessToken,
            refreshToken: refreshToken,
            promoterId: promoterId,
            phoneNumber: phoneNumber
        }))
        this.saveModule(1);
    },
    //读取user
    getUser(){
        // return JSON.parse(USER_KEY.getItem('user_key')||'{ }')
        return  JSON.parse(sessionStorage.getItem(USER_KEY))
    },
    //删除user
    deleteUser(){
        //localStorage.removeItem(USER_KEY);
        sessionStorage.removeItem(USER_KEY);
    },
    getMenuFromUser(menuList){
        const user = JSON.parse(sessionStorage.getItem(USER_KEY));
        const {permissionList} = user;
        if(menuList && menuList.length>0){
            menuList.forEach(m => {
                if(m.subs && m.subs.length>0){
                    m.subs = this.getMenuFromUser(m.subs);
                }
            });
            menuList = menuList.filter(item => permissionList.includes(item.permission));
        }
        return menuList;
    },
    getEndMenuList(menuList, list){
        if(menuList && menuList.length>0){
            menuList.forEach(item => {
                if(item.subs && item.subs.length>0){
                    this.getEndMenuList(item.subs, list);
                }else{
                    list.push(item);
                }
            })
        }
        return list;
    },
    getEndMenuUrlList(menuList, list){
        list = this.getEndMenuList(menuList, list);
        if(list && list.length>0){
            return list.map(item => item.key);
        }
        return [];
    },

    saveModule(value){
        sessionStorage.setItem(MODULE_KEY, JSON.stringify({
            moduleId:value,
        }))
    },

    getModule(){
        // return JSON.parse(USER_KEY.getItem('user_key')||'{ }')
        return  JSON.parse(sessionStorage.getItem(MODULE_KEY))
    },

    deleteModule(){
        //localStorage.removeItem(USER_KEY);
        sessionStorage.removeItem(MODULE_KEY);
    },


}
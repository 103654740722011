import React, {useEffect, useState} from 'react';
import { useHistory  } from "react-router-dom";
import {Button,Toast} from 'antd-mobile'
import phoneImg from '../../assets/Img/账号.png'
import mima from '../../assets/Img/密码.png'
import './css/home.css'
import {getVerificationCode,promoterHomeLogin} from '../../api/requstApi'
import sessionUtil from "../../utils/sessionUtils";
function PromoterHome() {
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [isSendVerificationCode,setIsSendVerificationCode] = useState(false);
    useEffect(()=>{
        document.title ="安巡代驾"
    })
    async function  goLogin(e){
        Toast.loading("加载中",0)
        console.log(phone,password)
        if(phone.length!=11){
            Toast.info("请输入正确的手机号")
            return
        }
        let value = {phoneNumber:phone,verifyCode:password}
        let res = await promoterHomeLogin(value)
        if(res.code==200){
            sessionStorage.setItem("user",JSON.stringify(res.result))
            Toast.hide()
            window.location.href="./#/promoterContent"

        }else{
            Toast.info(res.message)
        }

    }

    return (
        <div className={"PromoterHome"}>
            <div style={{color:"#f29c1f",fontSize:"22px"}}>安巡代驾</div>
            <div className={"boxInput"}>
                <img src={phoneImg} alt="" style={{width:"0.7rem",height:"0.7rem",marginLeft:"10px"}}/>
                <input  type="number" className={"inputlogin"}  placeholder={"请输入手机号"}
                       onChange={(e)=>{
                           setPhone(e.target.value)
                       }}
                />
            </div>
            <div  className={"boxInput"}>
                <img src={mima} alt="" style={{width:"0.7rem",height:"0.7rem",marginLeft:"10px"}}/>
                <input type={"number"} className={"inputlogin2"} placeholder={"请输入验证码"}
                       onChange={(e)=>{
                           setPassword(e.target.value)
                       }}
                />
                {
                    isSendVerificationCode?
                        <div style={{color:"#f29c1f",fontSize:"0.35rem",marginLeft:"10px"}}>验证码已发送</div>
                        :
                        <div style={{color:"#f29c1f",fontSize:"0.35rem",marginLeft:"10px"}}
                             onClick={async ()=>{
                                 if(phone.length!=11){
                                     Toast.info("请输入正确的手机号")
                                     return
                                 }
                                 setIsSendVerificationCode(true)
                                 let value = {phoneNumber:phone}
                                 let res = await getVerificationCode(value)
                                 if(res.code==200){
                                     Toast.success("获取成功")
                                     setTimeout(()=>{
                                         setIsSendVerificationCode(false)
                                     },60000)
                                 }else{
                                     Toast.info(res.message)
                                     setTimeout(()=>{
                                         setIsSendVerificationCode(false)
                                     },30000)
                                 }
                             }}
                        >获取验证码</div>
                }

            </div>
            <div style={{display:"flex",justifyContent:"center",marginTop:"30px"}}>
                <button className={"btnlogin"} onClick={goLogin}>登录</button>
            </div>
        </div>
    );
}
export default PromoterHome
import React, {Component} from 'react';
import {Toast, Steps, WingBlank, WhiteSpace, List, InputItem, Button, Picker, DatePicker, ImagePicker} from 'antd-mobile';
import { createForm } from 'rc-form';
import "./registry.less";
import {reqGetAudit2, reqDriverUpload, reqDriverCommitData,reqGetProvinceList,reqDriverCenter} from "../../../api/index";
import sessionUtil from '../../../utils/sessionUtils'
import {SUCCESS} from "../../../utils/constants";
import idCard1 from "../../../assets/images/idCard1.png"
import idCard2 from "../../../assets/images/idCard2.png"
import licenseBa from "../../../assets/images/licenseBa.png"
import licenseOn from "../../../assets/images/licenseOn.png"
const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);
const Step = Steps.Step;
const driverClassData = [
    {value:"A1", label:"A1"},
    {value:"A2", label:"A2"},
    {value:"A3", label:"A3"},
    {value:"B1", label:"B1"},
    {value:"B2", label:"B2"},
    {value:"C1", label:"C1"},
    {value:"C2", label:"C2"},
    {value:"C3", label:"C3"},
    {value:"C4", label:"C4"},
    {value:"C5", label:"C5"},
    {value:"D", label:"D"},
    {value:"E", label:"E"},
    {value:"F", label:"F"},
    {value:"M", label:"M"},
    {value:"N", label:"N"},
    {value:"P", label:"P"},
]
class Registry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep:0,
            driverLicenseFrontImgFiles:[],
            driverLicenseFrontImg:"",
            driverLicenseBackImgFiles:[],
            driverLicenseBackImg:"",
            idCardFrontImgFiles:[],
            idCardFrontImg:"",
            idCardBackImgFiles:[],
            idCardBackImg:"",
            uploadToken:"",
            auditStatus: 1,
            rejectReason:"信息审核成功",
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        console.log(this.state)
        this.queryAudit();
        // this.queryFormData();
        this.getProvince()
    }

    render() {
        const {rejectReason,auditStatus,currentStep,driverLicenseFrontImgFiles,driverLicenseBackImgFiles,
            idCardFrontImgFiles,idCardBackImgFiles, driverLicenseFrontImg,
            driverLicenseBackImg, idCardFrontImg, idCardBackImg} = this.state;
        const { getFieldProps } = this.props.form;
        const content_list = {
            "step_1":<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div style={{fontSize:"20px"}}>信息审核中...</div>
            </div>,
            "step_2":<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div>
                    <div style={{fontSize:"20px",marginBottom:"20px"}}>{rejectReason}</div>
                    {auditStatus===30 ? <Button type={"primary"} onClick={this.reStart} style={{padding:"0 15px"}}>重新提交</Button>
                        :
                        <div style={{fontSize:"20px"}}>审核通过</div>}
                </div>
            </div>,
            "step_0":<div>
                <div className={"form-content"}>
                    <List>
                        <InputItem{...getFieldProps('driverName')} clear>姓名</InputItem>
                        <InputItem{...getFieldProps('idCardNo')} clear>身份证号</InputItem>
                        <Picker data={[{value:1, label:"男"}, {value:2, label:"女"}]} cols={1} {...getFieldProps('sex')} >
                            <List.Item arrow="horizontal">性别</List.Item>
                        </Picker>
                        <DatePicker
                            mode="date"
                            extra="请选择"
                            {...getFieldProps('idCardExpiryDate')}
                            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-1, 0, 0, 0)}
                            maxDate={new Date(new Date().getFullYear()+20, 1, 1, 23, 59, 59)}
                            title={"选择昨天为长期有效"}
                            format={this.dateFormatter}
                        >
                            <List.Item arrow="horizontal">身份证有效期至</List.Item>
                        </DatePicker>
                        <Picker
                            data={this.state.provinceDate}
                            cols={2}
                            {...getFieldProps('code')}
                            onOk={this.onOkProvince}
                        >
                            <List.Item arrow="horizontal">选择地区</List.Item>
                        </Picker>
                        <Picker
                            data={this.state.driverCenterList}
                            cols={1}
                            {...getFieldProps('serviceCenterId')}
                        >
                            <List.Item arrow="horizontal">选择司服中心</List.Item>
                        </Picker>
                        <Picker data={driverClassData} cols={1} {...getFieldProps('driverLicenseClass')} >
                            <List.Item arrow="horizontal">驾驶证类型</List.Item>
                        </Picker>
                        <DatePicker
                            mode="date"
                            extra="请选择"
                            {...getFieldProps('firstIssueDate')}
                            maxDate={new Date()}
                            minDate={new Date(new Date().getFullYear()-30, 1, 1, 23, 59, 59)}
                        >
                            <List.Item arrow="horizontal">初次领证日期</List.Item>
                        </DatePicker>
                        <DatePicker
                            mode="date"
                            extra="请选择"
                            {...getFieldProps('licenseExpiryDate')}
                            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-1, 0, 0, 0)}
                            maxDate={new Date(new Date().getFullYear()+20, 1, 1, 23, 59, 59)}
                            title={"选择昨天为长期有效"}
                            format={this.dateFormatter}
                        >
                            <List.Item arrow="horizontal">有效截止日期</List.Item>
                        </DatePicker>
                    </List>
                </div>
                <div className={"form-content"}>
                    <div style={{margin:"20px 0"}}>

                        <div style={{display:'flex',justifyContent:"space-around"}}>
                            <div style={{width:"40%",position:"relative"}}>
                                <div className="idc1"></div>
                                <ImagePicker
                                    files={idCardFrontImgFiles}
                                    length={1}
                                    selectable={idCardFrontImgFiles.length<1}
                                    onChange={async (files)=>{this.setState({idCardFrontImgFiles:files, idCardFrontImg:await this.uploadImg(files)})}}
                                    // onChange={async (files)=>{this.setState({idCardFrontImg:await this.uploadImg(files,'idCardFrontImgFiles')})}}

                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    multiple={false}
                                />
                            </div>

                            <div style={{width:"40%",position:"relative"}}>
                                <div className="idc2"></div>
                                <ImagePicker
                                    files={idCardBackImgFiles}
                                    length={1}
                                    selectable={idCardBackImgFiles.length<1}
                                    onChange={async (files)=>{this.setState({idCardBackImgFiles:files, idCardBackImg:await this.uploadImg(files)})}}
                                    // onChange={async (files)=>{this.setState({idCardBackImg:await this.uploadImg(files,'idCardBackImgFiles')})}}

                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    multiple={false}
                                />
                            </div>

                        </div>
                    </div>
                    <div style={{margin:"20px 0"}}>

                        <div style={{display:'flex',justifyContent:"space-around"}}>
                            <div style={{width:"40%",position:"relative"}}>
                                <div  className="idc3"></div>
                                <ImagePicker
                                    files={driverLicenseFrontImgFiles}
                                    length={1}
                                    selectable={driverLicenseFrontImgFiles.length<1}
                                    onChange={async (files)=>{this.setState({driverLicenseFrontImgFiles:files, driverLicenseFrontImg:await this.uploadImg(files)})}}
                                    // onChange={async (files)=>{this.setState({driverLicenseFrontImg:await this.uploadImg(files,'driverLicenseFrontImgFiles')})}}

                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    multiple={false}
                                />
                            </div>

                            <div style={{width:"40%",position:"relative"}}>
                                <div className="idc4"></div>
                                <ImagePicker
                                    files={driverLicenseBackImgFiles}
                                    length={1}
                                    selectable={driverLicenseBackImgFiles.length<1}
                                    onChange={async (files)=>{this.setState({driverLicenseBackImgFiles:files, driverLicenseBackImg:await this.uploadImg(files)})}}
                                    // onChange={async (files)=>{this.setState({ driverLicenseBackImg:await this.uploadImg(files,'driverLicenseBackImgFiles')})}}

                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    multiple={false}
                                />
                            </div>

                        </div>

                    </div>

                </div>
                <Button type={"primary"} onClick={this.commitData} style={{width:"95%",margin:"auto"}} >
                    提交注册
                </Button>
            </div>
        }
        return (<div className={"main_page"}>
            <div className={"step-content"}>
                <Steps current={currentStep} direction="horizontal" size="small">
                    <Step key={0} title={<span style={{fontSize:"10px"}}>信息填报</span>} />
                    {/*<Step key={1} title={<span style={{fontSize:"10px"}}>证件上传</span>} />*/}
                    <Step key={1} title={<span style={{fontSize:"10px"}}>信息审核</span>} />
                    <Step key={2} title={<span style={{fontSize:"10px"}}>{auditStatus && auditStatus===30?"信息审核失败":"信息审核成功"}</span>} />
                </Steps>
            </div>
            {content_list["step_"+currentStep]}
        </div>);
    }

    commitData=async ()=>{
        const form = this.props.form;
        const _that = this;
        const formData = form.getFieldsValue();
        const {driverLicenseFrontImg, driverLicenseBackImg, idCardFrontImg, idCardBackImg} = this.state;
        const pattern = new RegExp( /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, "g");

        if(!formData.code){
            Toast.info("请选择地区")
            return
        }
        if(!formData.driverName){
            Toast.info("请输入名字")
            return
        }
        if(!formData.idCardNo){
            Toast.info("请输入身份证号")
            return
        }
        if(!pattern.test(formData.idCardNo)){
            Toast.info("身份证号格式错误")
            return
        }
        if(!formData.idCardExpiryDate){
            Toast.info("请选择身份证有效截止日期")
            return
        }
        if(!formData.driverLicenseClass){
            Toast.info("请选择驾驶证类型")
            return
        }
        if(!formData.firstIssueDate){
            Toast.info("请选择驾照初次领证日期")
            return
        }
        if(!formData.licenseExpiryDate){
            Toast.info("请选择驾照有效截止日期")
            return
        }
        if(!formData.sex){
            Toast.info("请选择性别")
            return
        }
        if(!formData.serviceCenterId){
            Toast.info("请选择司服中心")
            return
        }
        if(!driverLicenseFrontImg){
            Toast.info("请上传驾照正面图片")
            return
        }
        if(!driverLicenseBackImg){
            Toast.info("请上传驾照背面图片")
            return
        }
        if(!idCardFrontImg){
            Toast.info("请上传身份证正面图片")
            return
        }
        if(!idCardBackImg){
            Toast.info("请上传身份证背面图片")
            return
        }


        formData.driverLicenseFrontImg = driverLicenseFrontImg;
        formData.driverLicenseBackImg = driverLicenseBackImg;
        formData.idCardFrontImg = idCardFrontImg;
        formData.idCardBackImg = idCardBackImg;
        formData.serviceCenterId = formData.serviceCenterId[0];
        formData.sex = formData.sex[0];
        formData.driverLicenseClass = formData.driverLicenseClass[0];
        const user = sessionUtil.getUser();
        let param = {
            dto: formData,
            cookie: user.accessToken,
            promoterId: user.promoterId,
            provinceCode: formData.code[0],
            cityCode: formData.code[1],
            phoneNumber: sessionUtil.getUser().phoneNumber,
            serviceCenterId: formData.serviceCenterId
        }
        const res = await reqDriverCommitData(param);
        if(res.code === SUCCESS){
            Toast.success("提交成功，等待审核", 2)
            this.setState({
                currentStep: 1,
            })
        }else{
            Toast.fail("提交失败：" + res.message, 2);
        }
    }

    // queryAudit=async ()=>{
    //     const user = sessionUtil.getUser();
    //     const res = await reqGetAudit(user.accessToken);
    //     if(res.code === SUCCESS){
    //         const {state, rejectReason} = res.result;
    //         let currentStep = 0;
    //         if(state === 10){
    //             currentStep = 0;
    //         }else if(state === 20){
    //             currentStep = 1;
    //         }else {
    //             currentStep = 2;
    //         }
    //         this.setState({
    //             currentStep: currentStep,
    //             auditStatus: state,
    //             rejectReason: rejectReason
    //         })
    //     }else{
    //         // Toast.fail("获取审核状态失败", 2);
    //     }
    // }

    queryAudit=async ()=>{
        const user = sessionUtil.getUser();
        const res = await reqGetAudit2(user.phoneNumber);
        if(res.code === SUCCESS){
            const {state, rejectReason} = res.result;
            let currentStep = 0;
            if(state === 10){
                currentStep = 0;
            }else if(state === 20){
                currentStep = 1;
            }else {
                currentStep = 2;
            }
            this.setState({
                currentStep: currentStep,
                auditStatus: state,
                rejectReason: rejectReason
            })
        }else{
            // Toast.fail("获取审核状态失败", 2);
        }
    }

    // queryFormData = async ()=>{
    //     const user = sessionUtil.getUser();
    //     const res = await reqQueryFormData(user.accessToken);
    //     if(res.code === SUCCESS){
    //         this.setState({
    //             uploadToken: res.result.uploadToken
    //         })
    //     }else{
    //         // Toast.fail("网络错误", 2);
    //     }
    // }
    // selectP=async (e)=>{
    //     console.log(e)
    //     // let res = await reqGetProvinceSecondList({id:"5faa03621a944f52fab88c10",code:"310000"})
    //     const {provinceDate} = this.state
    //     let index =  provinceDate.findIndex((item)=>{return item.value==e[0]})
    //
    //     if (!provinceDate[index].children.length>0){
    //         let res = await reqGetProvinceSecondList({code:e[0]})
    //         let obj ={}
    //         let arr1 =[]
    //         res.results.map(async (item,index)=>{
    //             obj = {
    //                 label:item.name,
    //                 value:item.code,
    //             }
    //             arr1.push(obj)
    //         })
    //         let arr =[...provinceDate]
    //         arr[index].children =arr1
    //         this.setState({
    //             provinceDate:arr
    //         })
    //     }
    //
    // }
    onOkProvince=async (e)=>{
        console.log(e)
        let res = await reqDriverCenter({provinceCode:e[0],cityCode:e[1]})
        console.log(res)
        let arr = []
        res.results.map((item,index)=>{
            let obj = {
                value:item.id,
                label:item.name,
            }
            arr.push(obj)
        })
        this.setState({
            driverCenterList:arr
        })
    }
    getProvince=async ()=>{
        let res =await reqGetProvinceList()
        console.log(res)
        this.setState({
            provinceDate:res.results
        },()=>{
            console.log(this.state.provinceDate)
        })

    }
    //回到第一步
    reStart=()=>{
        this.setState({
            currentStep:0
        })
    }

    uploadImg=async (imgs)=>{
        if(imgs && imgs.length>0){
            let imgData = await this.dealImage(imgs[0].url,1500)
            const {uploadToken} = this.state;
            let param = {
                token: uploadToken,
                base64: imgData
            }
            const res = await reqDriverUpload(param);
            if(res.code === SUCCESS){
                return res.result;
            }else{
                Toast.fail("上传图片失败", 2);
            }
        }else{
            return "";
        }
    }

    dealImage=(base64, w)=> {
        var newImage = new Image();
        var quality = 0.6;    //压缩系数0-1之间
        newImage.src = base64;
        newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
        var imgWidth, imgHeight;
        var base64=""
        return  new Promise((resolve)=>{
            newImage.onload =async function () {
                imgWidth = this.width;
                imgHeight = this.height;
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                if (Math.max(imgWidth, imgHeight) > w) {
                    if (imgWidth > imgHeight) {
                        canvas.width = w;
                        canvas.height = w * imgHeight / imgWidth;
                    } else {
                        canvas.height = w;
                        canvas.width = w * imgWidth / imgHeight;
                    }
                } else {
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                    quality = 0.6;
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
                // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
                // while (base64.length / 1024 > 150) {
                // 	quality -= 0.01;0
                // 	base64 = canvas.toDataURL("image/jpeg", quality);
                // }
                // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
                // while (base64.length / 1024 < 50) {
                // 	quality += 0.001;
                // 	base64 = canvas.toDataURL("image/jpeg", quality);
                // }
                return resolve(base64)
            }
        })

    }

    dateFormatter=(datePicked)=>{
        if(datePicked){
            //昨天返回长期
            const pickedYear = datePicked.getFullYear();
            const pickedMonth = datePicked.getMonth();
            const pickedDay = datePicked.getDate();
            const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
            const yesterdayYear = yesterday.getFullYear();
            const yesterdayMonth = yesterday.getMonth();
            const yesterdayDay = yesterday.getDate();
            if( pickedYear === yesterdayYear
                && pickedMonth === yesterdayMonth
                && pickedDay === yesterdayDay){
                return "长期";
            }
            return pickedYear + "-" + (pickedMonth+1) + "-" + (pickedDay)
        }
        return null;
    }

}

const RegistryWrapper = createForm()(Registry);
export default RegistryWrapper;
import  {HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApi} from './AxiosUtils'
import {URL} from '../config/urlConf/urlConf'
import jsonp from 'jsonp';
import {values} from "mobx";
import {Toast} from 'antd-mobile'
//{login调用}
//登录接口
export const reqLogin = (value) =>{
    return HttpClientpost(URL+"/api/usercenter/login",value);
}

//上传图片接口(获取权限)
export const reqCompoundGetAuth =(file)=>HttpClientget(URL+"/api/aliyun/getAuth",{},{});

//apk接口
//获取上传授权
export const reqGetUploadAuth=()=>HttpClientget(URL + "/api/apk/getAuth", {}, {});

//司机接口
//获取图片验证码
// export const reqGetImageVerifyCode=()=>HttpClientget(URL + "/api/common/driver/getImageVerifyCode", {}, {});
//发送短信验证码
export const reqSendVsCode=(param)=>HttpClientpost(URL + "/api/common/driver/sendVerifyCode", {...param}, {})
//登录
export const reqDriverLogin=(mobile, verifyCode)=>HttpClientget(URL + "/api/common/driver/login", {mobile:mobile, verifyCode:verifyCode} ,{})
//驾驶员查询审核状态
// export const reqGetAudit=(cookie)=>HttpClientget(URL + "/api/common/driver/queryAudit", {cookie: cookie} , {});
//驾驶员电话
export const reqGetAudit2=(phoneNumber)=>HttpClientget(URL + "/api/common/driver/queryAudit2", {phoneNumber:phoneNumber} , {});
//驾驶员上传
export const reqDriverUpload=(param)=>HttpClientpost(URL + "/api/common/driver/upload", {...param}, {});
//驾驶员提交表单
export const reqDriverCommitData=(param)=>HttpClientpost(URL + "/api/common/driver/commitData", {...param}, {});
//查询表单信息
// export const reqQueryFormData=(cookie)=>HttpClientget(URL + "/api/common/driver/queryFormData", {cookie: cookie} , {});
//获取所有省份
export const reqGetProvinceList=(value)=>HttpClientget(URL + "/api/common/getZoneData", {} , {});
//获取司机服务中心
export const reqDriverCenter=(value)=>HttpClientget(URL + "/api/common/serviceCenter/getServiceCenterList", value, {});

/**
 * jsonp 请求的接口请求函数
 */
export const reqWeather = () =>{
    return new Promise((resolve,reject)=>{
        const  url = "https://restapi.amap.com/v3/weather/weatherInfo?city=500000&key=e2d5ef9729c069c0db50d2173572d12f";
        jsonp(url,{},(error,data)=>{
            console.log('jsonp',error,data);
            if(!error && data.status==='1'){
                console.log(data);
                resolve(data);
            }else {
                Toast.fail("网络错误", 1);
            }
        })
    })
}
/**
 *包含 N 个reducer模块
 */
import {INCREMENT,DECREMENT,UPDATE} from './action-types'

export function globalTheme(state = 'light',action) {
    console.log('globalTheme()',state,action)
    switch (action.type){
        case UPDATE:
            return state = action.data
    }
}
/**
 * Created by 12728 on 2020/10/12.
 */
const menuList = [
    {
        title: '资料设置',
        icon: 'laptop',
        key: '/data',
        permission: 'data-admin',
        subs: [
            {key: '/data/apk', title: 'apk管理', icon: '', permission: 'data-admin-apk',},
            {key: '/data/group', title: '分组管理', icon: '', permission: 'data-admin-group',},
            {key: '/data/device', title: '设备管理', icon: '', permission: 'data-admin-device',},
        ]
    },
    {
        title: '后台管理',
        icon: 'laptop',
        key: '/backend',
        permission: 'system-admin',
        subs: [
            {key: '/backend/organization', title: '代理商管理', icon: '', permission: 'system-admin-organization'},
            {key: '/backend/role', title: '角色管理', icon: '', permission: 'system-admin-role'},
            {key: '/backend/user', title: '账号管理', icon: '', permission: 'system-admin-account'},
            {key: '/backend/license', title: '授权码管理', icon: '', permission: 'system-admin-license'},
        ]
    },

]
export default menuList
import React, {Component} from 'react';
import './home.less'

import join from '../../../assets/images/join.png'


export default class Home extends Component {
    constructor(props) {
        super(props)
    }

    toJoin() {
        this.props.history.push('/login')
    }

    render() {
        return (
            <div className='home'>
                <div className="title">加盟须知</div>
                <img src={join} alt="" className='img'/>
                <div className='btn_box'>
                    <div className="btn" onClick={() => this.toJoin()}>我要加盟</div>
                </div>
            </div>
        )
    }
}
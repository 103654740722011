/**
 *404页面
 */
import React, {Component} from 'react'
import { Result, Button } from 'antd-mobile';
export default class NotFound extends Component {
    render() {
        return (
            <div style={{width:'100%',height:'800px'}}>
                <Result
                    status="404"
                    title="404"
                    subTitle="抱歉，您访问的页面不存在。"
                    extra={<Button type="primary" onClick={this.goHome}>回到首页</Button>}
                />
            </div>

        )
    }
    goHome=()=>{
        this.props.history.replace("/");
    }
}
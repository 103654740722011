import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux'
import {globalTheme} from './redux/reducers'
import App from './App';

// const user = storageUtils.getUser();
// memeryUtils.user = user;

const store = createStore(globalTheme)
//console.log(store)
function render() {
    ReactDOM.render(<App store = {store}/>,document.getElementById('root'));
}
render()
store.subscribe(render)
